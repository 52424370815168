import React, { useContext } from "react";
import { Link } from "react-router-dom";
import { GlobalDataContext } from "../../context/context";

function HeroSection() {
  const { rpdata } = useContext(GlobalDataContext);
  return (
    <>
      <div className="pic-wrapper">
        <figure
          className="pic-1"
          style={{ background: `url("https://firebasestorage.googleapis.com/v0/b/gallery-ae580.appspot.com/o/fire-wall-and-spark-with-black-3d-illustration.jpg?alt=media&token=c38420bc-39b9-460c-8a9b-58a5097d32f0")` }}
        ></figure>
        <figure
          className="pic-2"
          style={{ background: `url("https://firebasestorage.googleapis.com/v0/b/gallery-ae580.appspot.com/o/fire-wall-and-spark-with-black-3d-illustration.jpg?alt=media&token=c38420bc-39b9-460c-8a9b-58a5097d32f0")` }}
        ></figure>
        <figure
          className="pic-3"
          style={{ background: `url("https://firebasestorage.googleapis.com/v0/b/gallery-ae580.appspot.com/o/fire-wall-and-spark-with-black-3d-illustration.jpg?alt=media&token=c38420bc-39b9-460c-8a9b-58a5097d32f0")` }}
        ></figure>
        <figure
          className="pic-4"
          style={{ background: `url("https://firebasestorage.googleapis.com/v0/b/gallery-ae580.appspot.com/o/fire-wall-and-spark-with-black-3d-illustration.jpg?alt=media&token=c38420bc-39b9-460c-8a9b-58a5097d32f0")` }}
        ></figure>
        <div className="container">
          <div className="z4 text-slider position-relative text-center d-flex flex-column align-items-center items-center mx-auto">
            <h4 className="text-white text-center pd-top-100">
              {rpdata?.dbSlogan?.[5].slogan}
            </h4>
            <div className="md:w-[10%] w-full h-0.5 bg-white my-2"></div>
            <h1 className="text-white text-center text-responsive">
              {rpdata?.dbSlogan?.[0].slogan}
            </h1>
            <a
              className="btn btn-base text-white"
              href={`tel:+1${rpdata?.dbPrincipal?.phones[0].phone}`}
            >
              <i className="fa fa-phone-alt"></i>
              {rpdata?.dbPrincipal?.phones[0].phone}
            </a>
          </div>
        </div>
      </div>
    </>
  );
}

export default HeroSection;
